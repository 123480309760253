import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import styled from 'styled-components'
import Box from '@local/shared/components/System/Box'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const Underline = styled.span`
  text-decoration: underline;
`

const NoBreak = styled.span`
  white-space: nowrap;
`

const Subtitle = styled(Typography)`
  font-weight: normal;
`

const ButtonStyled = styled(Typography)`
  transition-duration: 0.25s;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
    background-color: ${({ bgcolorHover }) => bgcolorHover};
  }
  vertical-align: middle;
  color: black;
`

const VARIANT_PROPS = {
  primary: {
    bgcolor: 'yellow.dark1',
    bgcolorHover: 'yellow.0',
    border: 'none',
    pl: '1em',
    pr: '1em',
    py: '0.75em',
  },
  secondary: {
    bgcolor: 'white',
    bgcolorHover: 'yellow.0',
    border: 'solid 0.25em',
    borderColor: 'blue.0',
    pl: '1em',
    pr: '1em',
    py: '0.75em',
  },
  default: {
    bgcolor: 'yellow.light0',
    bgcolorHover: 'yellow.0',
    border: 'solid 0.125em',
    borderColor: 'yellow.dark1',
    pl: '1em',
    pr: '1em',
    py: '0.5em',
  },
}
const SIZES = { lg: 'button', sm: 'button-sm' }

const Button = ({
  children,
  size = 'lg',
  variant = 'primary',
  leftIcon,
  rightIcon,
  onClick,
  trackingName,
  ...props
}) => {
  const mixpanel = useMixpanel()
  const handleClick = e => {
    mixpanel.track('Button Clicked', { name: trackingName })
    onClick && onClick(e)
  }
  return (
    <ButtonStyled
      variant={SIZES[size]}
      borderRadius={50}
      {...VARIANT_PROPS[variant]}
      onClick={handleClick}
      {...props}
      pl={leftIcon ? '0.5em' : VARIANT_PROPS[variant]['pl']}
      pr={rightIcon ? '0.5em' : VARIANT_PROPS[variant]['pr']}
    >
      {leftIcon && <Box as={leftIcon} css={{ verticalAlign: 'middle' }} />}
      <span>{children}</span>
      {rightIcon && <Box as={rightIcon} css={{ verticalAlign: 'middle' }} />}
    </ButtonStyled>
  )
}

const MainCta = ({ children, mainContent, subtitleText, ...props }) => {
  return (
    <Button
      {...props}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box>{mainContent}</Box>
      {subtitleText && (
        <Subtitle variant={'subnote'} mt={'0.5em'}>
          {subtitleText}
        </Subtitle>
      )}
    </Button>
  )
}

const MainCta1 = props => (
  <MainCta
    mainContent={
      <>
        {' '}
        <NoBreak>
          Book <Underline>Free</Underline>
        </NoBreak>
        <br />
        <NoBreak>30-Minute</NoBreak>
        <br />
        <NoBreak>Phone</NoBreak> Consultation
      </>
    }
    {...props}
  />
)

const MainCta2 = props => {
  return (
    <MainCta
      mainContent={
        <>
          Book <Underline>Free</Underline> Consultation
        </>
      }
      subtitleText={'Yes, I want to talk to an expert to help me hit my goals!'}
      {...props}
    />
  )
}

const EbookCta = props => (
  <MainCta mainContent={<>Download Free eBook</>} {...props} />
)

export { MainCta, MainCta1, MainCta2, EbookCta }

export default Button

import React from 'react'
import { Helmet } from 'react-helmet'

const CalendlyButton = ({ email, name, button, children, ...props }) => {
  const Button = button
  const handleClick = e => {
    e.preventDefault()
    window.Calendly.showPopupWidget(
      `https://calendly.com/wildlife-coaching/free-consultation?email=${email}&name=${name}`
    )
  }

  return (
    <>
      <Helmet>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        />
      </Helmet>
      <Button onClick={handleClick} {...props}>
        {children}
      </Button>
    </>
  )
}

export default CalendlyButton

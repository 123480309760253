import Box from '@local/shared/components/System/Box'
import React from 'react'
import { Waypoint } from 'react-waypoint'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const Section = ({ children, trackingName }) => {
  const mixpanel = useMixpanel()
  return (
    <Waypoint
      onEnter={() => mixpanel.track('Entered Section', { name: trackingName })}
      fireOnRapidScroll={false}
    >
      <Box bgcolor={'white'} my={'4em'} px={'1em'}>
        <Box maxWidth={'800px'} margin={'auto'}>
          {children}
        </Box>
      </Box>
    </Waypoint>
  )
}

export default Section

import React from 'react'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { Typography } from '@local/shared/components/System'
import Section from 'src/components/Section'
import CalendlyButton from 'src/components/CalendlyButton'
import { MainCta } from 'src/components/Button'

const ThankYouPage = ({ location }) => {
  console.log('location', location)
  return (
    <PageLayout>
      <Seo title="Thank You" />
      <Section>
        <Typography variant={'h1'} mb={'0.5em'}>
          Thank you!
        </Typography>
        <Typography variant={'subtitle'}>
          Let's chat at a time that suits you.
        </Typography>
        <CalendlyButton
          mt={'1em'}
          name={location.state && location.state['full-name']}
          email={location.state && location.state.email}
          button={MainCta}
          trackingName={'book a time'}
          mainContent={'Book a Time'}
        >
          Book a Time
        </CalendlyButton>
      </Section>
    </PageLayout>
  )
}

export default ThankYouPage
